<template>
  <v-layout column>
    <v-layout justify-end align-center row wrap>
      <div>
        <v-btn color="primary" @click="generateReport">{{
          $t("global.generateReport")
        }}</v-btn>
      </div>
    </v-layout>
    <ContactQuarantineTable
      :ref="tableRefName"
      @processing="dataProcessing"
    ></ContactQuarantineTable>
    <v-layout>
      <PrintSave
        :disabled="!dataLoaded"
        :file-url="downloadReportUrl"
        @printClick="printReport"
      ></PrintSave>
    </v-layout>
  </v-layout>
</template>
<script>
import Printer from "../common/HtmlPrinter";
import ContactQuarantineTable from "../components/Reports/ContactQuarantineTable";
import PrintSave from "../components/PrintSave";
import FileService from "../services/FileService.js"
export default {
  name: "ReportContactQuarantine",
  components: { ContactQuarantineTable, PrintSave },
  data() {
    return {
      tableRefName: "table",
      dataLoaded: false
    };
  },
  computed: {
    downloadReportUrl() {
      return FileService.ReportContactQuarantineUrl();      
    }
  },
  methods: {
    dataProcessing(loading) {
      this.dataLoaded = loading;
    },
    generateReport() {
      this.$refs[this.tableRefName].getData();
    },
    printReport() {
      Printer.PrintElement(
        this.$refs[this.tableRefName].$el,
        this.$t("reports.quarantineReport"),
        {
          landscape: true
        }
      );
    }
  }
};
</script>
